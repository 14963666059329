import * as React from 'react';
import { Box } from '@mui/material';
import '../App.css';
import Appbar from '../component/appbar';
import Foot from '../component/foot';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function Button_click() {
    
}

function Page2() {

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
      setAge(event.target.value);

    };

    const Button_click = () => {
        alert(age);
    };

    return (
        <div className="App">
            <Appbar />
            <header className="App-header">
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', py: 7 }}>
                    <Card sx={{ maxWidth: 500, m: 3 }}>
                        <CardMedia
                            sx={{ height: 350 }}
                            image="https://i.pinimg.com/564x/88/da/2e/88da2e425784e93c70adef4d98a36715.jpg"
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Lizard
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>

                                <Box sx={{ minWidth: 350 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                        <Select
                                             labelId="demo-simple-select-label"
                                             id="demo-simple-select"
                                             value={age}
                                             label="Age"
                                             onChange={handleChange}
                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="error" onClick={Button_click}>Submit</Button>
                        </CardActions>
                    </Card>
                </Box>
            </header>
            <Foot />
        </div>
    );
}
export default Page2;