import logo from './logo.svg';
import './App.css';
import Page1 from './pages/page1';
import Page2 from './pages/page2';
import Home from './pages/home';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
              <Route path='/' element={<Home/>} />
              <Route path='/page1' element={<Page1/>} />
              <Route path='/page2' element={<Page2/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
